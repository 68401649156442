<template>
  <v-dialog
    v-model="show"
    max-width="40vw"
    @click:outside="cancel"
  >
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <v-card-title class="justify-center text-h5">
        {{ label }}
      </v-card-title>
      <v-card-text>
        <v-form class="multi-col-validation mt-6" ref="form">
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="status"
                :items="orderStatesDropDown"
                :menu-props="{ maxHeight: '400' }"
                label="Наименование"
                dense
                outlined
                persistent-hint
                hide-details
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="code"
                label="Код"
                dense
                :error="hasErrorsForProperty(errors.errors, 'code')"
                :error-messages="errorMessages(errors.errors, 'code')"
                outlined
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-checkbox
                v-model="isDefault"
                label="По умолчанию"
                :error="hasErrorsForProperty(errors.errors, 'isDefault')"
                :error-messages="errorMessages(errors.errors, 'isDefault')"
                hide-details
              ></v-checkbox>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-checkbox
                v-model="isFinal"
                label="Финальный"
                :error="hasErrorsForProperty(errors.errors, 'isFinal')"
                :error-messages="errorMessages(errors.errors, 'isFinal')"
                hide-details
              ></v-checkbox>
            </v-col>

            <v-col
              md="12"
              cols="24"
            >
              <v-textarea
                v-model="icon"
                label="Иконка"
                :error="hasErrorsForProperty(errors.errors, 'icon')"
                :error-messages="errorMessages(errors.errors, 'icon')"
                dense
                outlined
              ></v-textarea>
            </v-col>

            <v-col cols="12">
              <v-btn
                @click="saveData"
                color="primary"
                class="me-3 mt-4"
              >
                Сохранить
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                class="mt-4"
                type="reset"
                @click="cancel"
              >
                Отмена
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiCheck, mdiClose } from '@mdi/js'
import { mapFormHandlers } from '@/helpers/forms'
import { mapActions, mapGetters } from 'vuex'
import { rules } from '@/helpers/validation'
import { hasErrorsForProperty, errorMessages } from '@/helpers'

export default {
  name: 'OrderStateForm',
  props: ['save', 'show', 'cancellation', 'label'],
  data: () => ({
    icons: {
      mdiCheck,
      mdiClose,
    },
    rules,
    hasErrorsForProperty,
    errorMessages,
  }),
  computed: {
    ...mapFormHandlers('orderState/form', [
      'status',
      'code',
      'icon',
      'isDefault',
      'isFinal'
    ]),
    ...mapGetters('dictionary', { orderStatesDropDown: 'orderStatesDropDown' }),
  },
  async mounted() {
    await this.fetchOrderStates()
  },
  watch: {
    show: function(newValue) {
      if (newValue && this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
  },
  methods: {
    cancel() {
      this.resetForm()
      this.cancellation()
    },
    ...mapActions('dictionary', {
      fetchOrderStates: 'fetchOrderStates',
    }),
    ...mapActions('orderState/form', ['resetForm']),
    async saveData() {
      if (this.$refs.form.validate()) {
        await this.save()
        this.resetForm()
      }
    },
  },
}
</script>
